@import "../../../assets/scss/variables";

.blog-container {
  margin: 40px 100px;

  .blog-head {
    margin-bottom: 30px;
    span {
      background: $mainColor1;
      padding: 10px 20px;
      color: #fff;
    }
  }

  .news-page-card {
    background: #ffffff;
    overflow: hidden;
    height: 350px;
    margin: 5px;
    display: flex;
    text-decoration: none;
    position: relative;
    flex-direction: column;
    box-shadow: 0px 0px 3px rgba(163, 163, 163, 0.562);

    &.nocolumn {
      flex-direction: row;
    }

    &:hover {
      .news-link {
        height: 100%;
        width: 100%;

        background-color: rgb(19, 15, 15);
        position: absolute;
        top: 0;
        opacity: 0.9;
        transition: all 0.4s ease-out 0s;
      }
    }

    .news-content {
      padding: 10px 10px 5px 10px;
      display: flex;
      flex-direction: column;
      height: 100%;

      h3 {
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
      }
      h5 {
        font-size: 16px;
        text-transform: uppercase;
      }

      span {
        margin-top: auto;
        text-transform: uppercase;
        font-size: 13px;
      }
    }

    .img-news {
      overflow: hidden;
      min-height: 180px;
      max-height: 180px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .news-link {
      height: 100%;
      width: 0%;
      color: #aaa;
      background-color: rgb(7, 7, 7);
      position: absolute;
      top: 0;
      opacity: 0;
      transition: all 0.4s ease-out 0s;
      text-align: center;
      padding-top: 180px;

      span {
        text-align: center;
        max-height: 400px;
        border: 1px solid #fff;
        display: inline-block;
        padding: 10px 30px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.post-content-section {
  padding: 0 0 10px 0;

  .post-title-block {
    // background: url(https://static.pexels.com/photos/268455/pexels-photo-268455.jpeg);
    // padding: 130px 0;
    height: 350px;

    overflow: hidden;
    text-align: center;
    background-size: fill !important;
    $bbg: rgb(53, 53, 53);
    color: #fff;
    position: relative;
    background-repeat: no-repeat !important;
    // background-size: cover !important;
    z-index: -1;
    position: relative;

    &::after {
      -webkit-backdrop-filter: blur(
        3px
      ); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
      backdrop-filter: blur(3px); /* Supported in Chrome 76 */
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.425);
    }

    .post-title-texts{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //   position: relative;
      z-index: 1;
      // padding: 35px 30px;
      width: 90%;
    }

    h1 {
  
    
      line-height: 1.1;
      font-size: clamp(28px, 2vw, 60px);
      margin-bottom: 0px;
      text-transform: capitalize;
    }

    span {
      z-index: 1;
      font-size: 20px;
    }
  }

  .post-content-block {
    padding: 10px 60px;

    ul {
      list-style: square;
      margin-left: 20px;
    }

    .image-block {
      padding: 20px 20px 20px 0;
    }
  }

  .side-t {
    margin-top: 15px;
    background: rgb(236, 236, 236);
    font-size: 22px;
    padding: 4px 10px 4px 0;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1.7;

    span {
      color: #fff;
      padding: 10px 10px;
      background-color: $mainColor1;
      margin-right: 5px;
    }
  }

  .recent-p {
    .list-group-item {
      border-radius: 0px;
      padding: 15px 10px 5px 10px;
      border: none;
      margin-bottom: 5px;

      h5 {
        font-size: 15px;
      }

      background: rgb(236, 236, 236);

      &:hover {
        background: rgb(255, 255, 255);
        box-shadow: 0 0 2px rgb(148, 148, 148);

        h5 {
          color: color(voi);
        }
      }
    }
  }
}






@media (max-width: 967.5px) {


  
  .post-title-block{

    h1{
      font-size: 20px !important;
    }
  }

  .post-content-section .post-content-block{
    padding: 10px 25px;
  }
}
