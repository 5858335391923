@import "./variables";

body {
  background-color: $whiteColor !important;
  font-family: $ff1 !important;
  color: $txt1 !important;
  font-weight: 500 !important;
  width: 100%;
  height: 100%;
  font-size: 15px !important;
  margin: auto;
  position: relative;
  padding-right: 0 !important;
  overflow-x: hidden !important;
  // margin-bottom: 600px !important;
}

//////////////////////////
///RESET/////////////////
////////////////////////
* {
  outline: none;
}

a {
  color: unset !important;
  text-decoration: none;

  &:focus,
  &:hover {
    color: unset;
    text-decoration: none;
    text-decoration: none;
    transition: 0.1s ease;
  }

  &:focus {
    outline: 0;
  }
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

//////////////////////////
///GLOBAL STYLES/////////
////////////////////////
.btn,
.form-control,
.form-select {
  &:focus {
    box-shadow: none !important;
  }
}

.btn {
  font-size: 0.8rem !important;
}

.form-check-input {
  box-shadow: none !important;
  // border: 1px solid $mainColor2;

  &:hover {
    background: $whiteColor;
    border: 5px solid rgba($mainColor1, 0.5);
  }

  &:checked {
    background: $whiteColor !important;
    border: 5px solid $mainColor1 !important;
  }
}

.badge-sm {
  font-size: .70em !important;
  font-weight: 600 !important;
}

.text-warning {
  font-size: 10px;
}

//////////////////////////
///NAVBAR////////////////
////////////////////////
.mainNav {
  background: linear-gradient(90deg,
      rgba($mainColor1, 1) 27%,
      rgba($mainColor2, 1) 100%);
  font-family: $ff1;
  height: 50px;
  z-index: 1;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .navbar-brand {
    img {
      max-width: 100px;
      margin-top: 50px;
      transition: max-width 0.3s linear;
    }
  }

  .navbar-toggler {
    margin-top: -19px;
    background-color: rgba($mainColor1, 1);
  }

  .nav-det {
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      color: $whiteColor !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;

      &:hover {
        color: $whiteColor !important;
        border-bottom: 3px solid $whiteColor;
        background: rgba($whiteColor, 0.1);
      }
    }
  }

  .nav-user {
    .profile-display {
      // margin-top: 20px;

      .profile-icon {
        border-radius: 50%;
      }

      img {
        height: 50px;
        border-radius: 50%;
        // object-fit: cover;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      color: $whiteColor !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      width: 100px;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      &.log {
        background: rgba($whiteColor, 0.1);
      }

      &:hover {
        color: $whiteColor !important;
        text-decoration: underline;
        text-decoration: none;
        color: $whiteColor !important;
        background-color: $mainColor1;
      }
    }
  }

  @media (max-width: 767.5px) {
    height: 40px;

    .navbar-brand {
      padding: 0 !important;

      img {
        margin-top: -0;
        max-width: 60px;
        position: relative;
        z-index: 1;
      }
    }

    .navbar-toggler {
      transform: scale(0.7);
      margin-top: -10px;
    }

    .collapsing {
      -webkit-transition: none !important;
      transition: none !important;
      display: none !important;
    }

    .navbar-collapse {
      background: linear-gradient(90deg,
          rgba($mainColor1, 1) 27%,
          rgba($mainColor2, 1) 100%);
      font-family: $ff1;
      margin-right: -0.75rem !important;
      margin-left: -0.75rem !important;
      position: relative;
      padding-top: 25px;
      margin-top: -20px;
    }

    .nav-det {
      background: rgba($whiteColor, 0.1);

      .nav-link {
        display: flex;
        align-items: center;
        justify-content: start;
        height: auto;
        color: $whiteColor !important;
        padding-right: 25px !important;
        padding-left: 25px !important;
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        font-size: 17px;

        &:hover {
          color: $whiteColor !important;
          border-bottom: 3px solid $whiteColor;
          background: rgba($whiteColor, 0.1);
        }
      }
    }

    .nav-user {
      padding: 5px !important;
      flex-direction: row !important;

      .nav-item {
        width: 50%;

        .nav-link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          color: $whiteColor !important;
          padding-right: 10px !important;
          padding-left: 10px !important;
          width: 100%;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.5px;

          &.log {
            background: rgba($whiteColor, 0.7);
            color: $mainColor1 !important;
          }

          &:hover {
            color: $mainColor1 !important;
            background-color: $whiteColor;
          }
        }
      }
    }
  }
}

//////////////////////////
///HOMESLIDER////////////
////////////////////////
.homeslider-container {
  height: 100%;
  width: 100%;

  .slick-slide>div {
    position: relative;
    margin-bottom: -7px;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(90deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 1) 70%);
      z-index: 2;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      // background: linear-gradient(180deg,
      //     rgba(255, 255, 255, 0) 30%,
      //     rgba(255, 255, 255, 1) 90%);
      z-index: 2;
    }
  }

  .homeslider {
    height: 100%;
    width: 100%;
    background: $whiteColor;
    height: 90vh;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .slick-dots {
    bottom: 15px;
    display: flex !important;
    padding-left: 10vh !important;

    li.slick-active button:before {
      opacity: 1;
      color: $mainColor1;
      text-shadow: -0.5px -0.5px 0 $whiteColor, 0.5px -0.5px 0 $whiteColor,
        -0.5px 0.5px 0 $whiteColor, 0.5px 0.5px 0 $whiteColor;
      font-size: 15px;
    }

    li button:before {
      font-size: 10px;
      position: absolute;
      color: $whiteColor;
      opacity: 1;
      text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000,
        -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
    }
  }
}

.amenitites_slider {
  height: 100%;
  width: 100%;

  .slick-slide>div {
    position: relative;
    margin-bottom: -7px;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: none !important;
      z-index: 2;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: none !important;
      z-index: 2;
    }
  }

  .slick-dots {
    bottom: -35px;
    display: block !important;
    // padding-left: 85vh !important;
    padding-left: 0 !important;
  }
}

//////////////////////////
///HOME FORM/////////////
////////////////////////
.busTicketForm-container {
  position: relative;
  margin-top: -47vh;
  margin-bottom: 47vh;

  .busTicketForm-div {
    background: $whiteColor;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-45%);
    border-radius: 20px 20px 8px 8px;
    box-shadow: 0px 0px 6px #00000029;
    width: 350px;
    margin-right: 5vw;

    .busTicketForm-title {
      border-radius: 20px 20px 0 0;
      padding: 13px 20px 10px 20px;
      background: $mainColor1;
      color: $whiteColor;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .busTicketForm {
      padding: 25px 15px 25px 15px;

      .form-label {
        font-size: 13px;
        margin-bottom: 0;
        text-transform: uppercase;
        color: #8f8f8f;
        display: block;
        margin-bottom: -5px;
      }

      .locationInput,
      .form-select {
        font-weight: 700;
        border: 0px;
        font-size: 20px;
        color: $txt1;
        padding: 5px 5px;
        margin-left: -5px;
      }

      .busDepartureDate {
        border: 0px;
        padding: 5px 10px 5px 0px;
        color: $txt1;
        font-size: 18px;
        font-weight: 600;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      #exchangeIcon {
        background: $mainColor1;
        color: $whiteColor;
        height: 25px;
        width: 25px;
        font-size: 18px;
        padding: 6px;
        border-radius: 100%;
        cursor: pointer;
        transition: all 0.1s linear;
        // animation: shake 1s infinite;
        // margin-left: -5px;

        &:hover {
          background: darken($mainColor2, 5%);
        }
      }

      .radio-container {
        display: flex;
        margin-top: 10px;

        .form-check {
          margin-right: 10px;
          // padding-left: 10px;

          .form-check-input {
            margin-left: -20px;
          }

          .radioLabel {
            font-weight: 600;
            color: $txt1;
            font-size: 15px;
            text-transform: uppercase;
          }
        }
      }

      .css-1s2u09g-control {
        border-color: transparent !important;
      }

      .css-1okebmr-indicatorSeparator {
        background-color: transparent !important;
      }

      .css-319lph-ValueContainer {
        padding-left: 0px;
      }

      .css-14el2xx-placeholder,
      .css-qc6sy-singleValue,
      .css-319lph-ValueContainer * {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $txt1 !important;
        line-height: 1;
      }

      .css-6j8wv5-Input,
      .css-14el2xx-placeholder,
      .css-1pndypt-Input {
        margin-left: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      .css-1pahdxg-control {
        border-color: transparent;
        box-shadow: 0 0 0 transparent;
      }
    }

    .busTicketForm-btn {
      background: $mainColor2;
      margin-top: 20px;
      padding: 8px 20px 7px 20px;
      font-size: 15px;
      text-transform: uppercase;
      border: none;
      font-weight: 700;
      border-radius: 5px;

      &:hover {
        background: darken($mainColor2, 5%);
      }
    }
  }
}

//////////////////////////
///WHY US////////////////
////////////////////////
.whyUs-section {
  padding: 0 !important;

  .section-title {
    // margin-top: -300px;
    height: 200px;
    width: 100%;
    position: relative;
    background: $whiteColor;

    &>img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      max-height: 200px;
      height: 100%;
      // object-fit: cover;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 40%);
      color: $whiteColor;
      font-weight: 700;
      font-size: 40px;
    }
  }

  .whyUs-item {
    text-align: center;
    padding: 10px;

    img {
      height: 120px;
      width: auto;
      max-width: 100%;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      font-weight: 200;
      padding: 0 10px;
      line-height: 1.2;
    }
  }
}

//////////////////////////
///CALL TO ACTION////////
////////////////////////
.CTA-section {
  background: $mainColor2;
  color: $whiteColor;
  padding: 30px 0 20px 0;

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    margin-bottom: 20px;
  }

  p {
    font-weight: 200;
    font-size: 14px;
    margin: 0;
  }

  .CTA-btn {
    background: $whiteColor;
    padding: 10px 25px 8px 25px;
    font-size: 18px;
    text-transform: uppercase;
    border: none;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 5px;
    color: $mainColor2;

    &:hover {
      background: darken($mainColor2, 5%);
    }
  }
}

.CTA-section-add-banner {
  height: 300px;
  overflow: hidden;

  .ad-banner {
    position: relative;
    height: 100%;

    .ad-desc {
      width: 100%;
      text-align: center;
      background-color: #00000083;
      position: absolute;
      // left: 50%;
      // top: 50%;
      bottom: 0;
      padding: 10px;
      // transform: translate(-50%, -50%);
      font-size: 18px;
      color: white;
      text-shadow: 1px 1px 2px black;
      z-index: 1;

      h1 {
        font-weight: 800 !important;

      }

    }

    img {
      object-position: center;
      object-fit: contain;
      width: 100%;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      z-index: 0.5;

    }

  }
}

//////////////////////////
///Top Routes////////////
////////////////////////
.routes-section {
  background: #f5f9fc;
  padding: 100px 0 80px 0;

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .row {
    width: 90%;
    margin: auto;

    @media (max-width: 767.5px) {
      width: 100%;
    }
  }

  .card-col {
    .card {
      border-radius: 5px;
      max-width: 350px;
      display: block;
      margin: 10px auto;
      overflow: hidden;

      .card-img {
        object-fit: cover;
        border-radius: 5px;
      }

      .card-img-overlay {
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        transition: all 0.1s linear;
        align-items: flex-end;
        justify-content: center;
      }

      .card-title {
        font-weight: 700;
        color: $whiteColor;

        @media (max-width: 767.5px) {
          font-size: 15px;
        }
      }

      &:hover {
        .card-img-overlay {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

//////////////////////////
///PAYMENT PARTNERS//////
////////////////////////
.partners-section {
  background: #f5f9fc;
  padding: 80px 0 80px 0;

  h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .row {
    width: 90%;
    margin: auto;

    @media (max-width: 767.5px) {
      width: 100%;
    }
  }

  .img-container {
    background: $whiteColor;

    img {
      object-fit: contain;
      max-width: 150px;
      border-radius: 5px;
      padding: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

//////////////////////////
///FOOTER////////////////
////////////////////////
.main-footer {
  background-color: $txt1;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 60px;

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 20px;
    // margin-top: 10px;
  }

  p {
    padding-right: 40px;
  }

  img {
    max-width: 100px;
  }

  .social {
    a {
      margin-right: 15px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5) !important;

      &:hover {
        color: $whiteColor !important;
      }
    }
  }

  h5 {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  ul {
    li {
      list-style: none;
      margin-bottom: 5px;

      a {
        text-decoration: none;

        &:hover {
          color: $whiteColor !important;
        }
      }
    }
  }

  .copy-footer {
    padding: 10px;
    text-align: center;
    background: rgba(0, 0, 0, 0.144);
  }
}

//////////////////////////
///ROUTE LIST////////////
////////////////////////
.gradient-block {
  background: linear-gradient(90deg,
      rgba($mainColor1, 1) 27%,
      rgba($mainColor2, 1) 100%);
  font-family: $ff1;
  height: 130px;
}

.busTicketForm-vertical {
  margin-top: -40px;

  .busTicketForm-div {
    background: $whiteColor;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 0px 15px #00000029;
    // overflow: hidden;

    .busTicketForm {
      padding: 0 0 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-label {
        font-size: 13px;
        margin-bottom: 0;
        text-transform: uppercase;
        color: #8f8f8f;
        display: block;
        margin-bottom: -5px;
      }

      .mb-3 {
        margin-bottom: 0px !important;
        width: 100%;
      }

      .locationInput,
      .form-select {
        font-weight: 700;
        border: 0px;
        font-size: 20px;
        color: $txt1;
        padding: 5px 5px;
        margin-left: -5px;
      }

      .busDepartureDate {
        border: 0px;
        padding: 5px 10px 5px 0px;
        color: $txt1;
        font-size: 18px;
        font-weight: 600;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      #exchangeIcon {
        background: $mainColor1;
        color: $whiteColor;
        height: 25px;
        width: 25px;
        font-size: 18px;
        padding: 6px;
        border-radius: 100%;
        cursor: pointer;
        margin-top: 8px;
        margin-left: 2px;
        margin-right: 8px;
        transition: all 0.1s linear;

        &:hover {
          background: darken($mainColor2, 5%);
        }
      }

      .radio-container {
        display: flex;
        margin-top: 10px;

        .form-check {
          margin-right: 10px;
          // padding-left: 10px;

          .form-check-input {
            margin-left: -20px;
          }

          .radioLabel {
            font-weight: 600;
            color: $txt1;
            font-size: 15px;
            text-transform: uppercase;
          }
        }
      }

      .city__control {
        border-color: transparent !important;
        box-shadow: 0 0 0 transparent;

        .city__value-container {
          padding-left: 0px;

          * {
            font-weight: 700 !important;
            font-size: 16px !important;
            color: $txt1 !important;
            line-height: 1;
          }

          .city__placeholder,
          .city__singleValue,
          .city__input-container {
            padding-left: 0;
            margin-left: 0;
          }
        }

        .city__indicators {
          .city__indicator-separator {
            background-color: transparent !important;
          }
        }
      }

      .city__menu {
        margin-top: 0px;
        border-radius: 0px;
        background: $whiteColor;

        .city__menu-list {
          .city__option {
            &:hover {
              color: $whiteColor;
            }

            &:active,
            &.is-selected {
              color: $whiteColor;
            }
          }
        }
      }
    }

    .busTicketForm-btn {
      background: $mainColor1;
      padding: 8px 20px 7px 20px;
      font-size: 17px;
      text-transform: uppercase;
      border: none;
      font-weight: 700;
      border-radius: 0 8px 8px 0;
      display: block;
      height: 80px;
      // margin-bottom: -10px;
      margin-top: -5px;
      margin-left: 1px;
      width: 100%;

      &:hover {
        background: darken($mainColor1, 5%);
      }
    }
  }

  @media (max-width: 991.5px) {
    margin-top: -80px;

    .busTicketForm-div {
      .busTicketForm {
        padding: 20px 15px 15px 15px;

        .form-label {
          font-size: 13px;
          margin-bottom: 0;
          text-transform: uppercase;
          color: #8f8f8f;
          display: block;
          margin-bottom: -5px;
        }

        .mb-3 {
          margin-bottom: 20px !important;
          width: 100%;
        }
      }

      .busTicketForm-btn {
        padding: 15px;
        font-size: 15px;
        text-transform: uppercase;
        border: none;
        font-weight: 700;
        border-radius: 5px;
        display: block;
        height: auto;
        margin-bottom: 0;
        margin-top: 10px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.routesFilter-container {
  margin-top: 40px;

  .m-thead {
    background: $txt1;
    color: $whiteColor;
    padding: 10px;
    align-items: center;
    margin: 0;

    @media (min-width: 767.5px) {
      display: none;
    }

    h1 {
      margin: 0;
      font-size: 22px;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.5px;
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      text-align: right;
      color: $txt1;
      background: $whiteColor;
      float: right;
      margin-bottom: 0;
      height: 30px;
      width: 30px;
      padding-top: 2.5px;
    }
  }

  .filter-col {
    border-right: 2px solid #707070;
    min-height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;

    .filter-item {
      display: flex;
      flex-direction: column-reverse;

      .filter-title {
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
        color: $txt1;
        padding: 10px 10px 2px 10px;
        margin-bottom: 15px;
        cursor: pointer;
        border-bottom: 2px solid #f0f0f0;
        transition: all 0.1s linear;

        &:hover {
          color: darken($txt1, 10%);
          border-bottom: 2px solid #e9e9e9;
        }
      }

      .form-check {
        padding: 5px 10px 5px 35px;
        margin-right: 20px;
        transition: all 0.2s linear;

        // margin-top: 10px;
        &:last-child {
          // margin-bottom: 10px;
        }

        label {
          margin-bottom: 0;
        }

        &:hover {
          background: rgb(245, 245, 245);

          label {
            padding-right: 10px;
          }
        }
      }

      .show~.filter-title {
        color: darken($txt1, 20%);
        border-bottom: 2px solid #e0e0e0;

        &:hover {
          color: darken($txt1, 20%);
          border-bottom: 2px solid #e0e0e0;
        }
      }
    }

    @media (max-width: 767.5px) {
      border-right: 0px solid #707070;

      .filter-item {

        .filter-title,
        .form-check {
          margin-right: 0;
        }
      }
    }
  }

  .routes-col {
    min-height: 200px;
    padding-top: 20px;
    padding-bottom: 20px;

    .thead {
      margin: 0;
      margin-bottom: 20px;

      @media (max-width: 767.5px) {
        display: none;
      }

      .th-head {
        font-weight: 700;
        // text-align: center;
        font-size: 16px;
        color: $txt1;
        cursor: pointer;
        line-height: 1;
        text-transform: uppercase;
      }
    }

    .route-lists {
      margin-top: 30px;

      .tbody {
        margin: 0;
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 4px #bdbdbd9f;
        padding: 5px 10px 20px 10px;
        line-height: 1.1;

        small {
          display: none;
          color: $mainColor2;
          margin-bottom: 5px;
        }

        strong {
          margin-top: 8px;
          font-weight: 600;
          display: block;
        }

        span {
          margin-top: 5px;
          display: block;
          font-size: 90%;
        }

        .seats {
          text-align: center;

          h4 {
            display: block;
            font-weight: 700;
            margin-bottom: -3px;
          }

          div {
            font-size: 90%;
          }
        }

        @media (max-width: 767.5px) {
          justify-content: space-between;
          padding: 5px 10px 15px 10px;

          small {
            display: block;
          }

          strong {
            margin-top: 0px;
            line-height: 0.9;
          }

          span {
            margin-bottom: 10px;
            margin-top: 1px;
          }

          .seats {
            text-align: left;

            h4 {
              display: block;
              font-weight: 700;
              margin-bottom: -3px;
            }

            div {
              font-size: 90%;
            }
          }
        }
      }
    }
  }
}

.bookSeats-steps {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin: 30px 0 30px 0;

  .steps {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // border: 1px solid #000;

    .stepsBox {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      &:before {
        content: "";
        border-bottom-color: lighten($txt1, 30%);
        border-bottom-width: 7px;
        border-bottom-style: dotted;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 8px;
        width: 100%;
        position: absolute;
      }

      &:first-child {
        width: 50px;
      }

      .icon {
        text-align: center;
        background-color: lighten($txt1, 30%);
        color: $whiteColor;
        display: inline-block;
        position: relative;
        width: 25px;
        height: 25px;
        border: 3.5px solid $whiteColor;
        border-radius: 50%;
        z-index: 5;

        h6 {
          margin-left: -35px;
          font-size: 13px;
          width: 90px;
          color: lighten($txt1, 30%);
          text-align: center;
          line-height: 1.05;
          margin-top: 25px;

          @media (max-width: 500px) {
            font-size: 10px;
            width: 30px;
            margin-left: -5px;
            text-align: center;
          }
        }
      }
    }

    .stepsBox.completed {
      &:before {
        border-bottom-color: $mainColor1 !important;
      }

      .icon {
        width: 30px;
        height: 30px;
        background-color: $mainColor1 !important;

        h6 {
          color: $txt1 !important;
          font-weight: 700;
          margin-top: 25px;
        }
      }
    }

    // .stepsBox.completed + .stepsBox:not(.completed) {
    //   &:before {
    //     background-color: $tableAlternateBgColor !important;
    //   }
    //   .icon {
    //     background-color: $addColor !important;
    //     color: $whiteColor;
    //     h6 {
    //       color: $addColor !important;
    //     }
    //   }
    // }
    &.canceled .stepsBox {
      &:before {
        border-bottom-color: red !important;
      }

      &.ongoing::before {
        border-bottom-color: red !important;
      }

      .icon {
        background-color: red;
        color: $whiteColor;

        h6 {
          color: red;
        }
      }
    }
  }
}

.typesDisplay {
  // display: flex;
  // align-items: center;
  box-shadow: 0px 1px 5px 1px rgb(214, 214, 214);
  position: absolute;
  width: 50%;
  // margin: 0 5px;
  margin: -10px 5px 15px 5px;
  background-color: $whiteColor;
}

.vechile-container2 {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;

  #main_layer {
    width: 160px;
    max-width: 180px;
    position: relative;
    top: 0;
    right: 0;
    background: #707070;
    border-radius: 28px;
    margin: 20px 10px 15px 10px;

    &:after {
      display: block;
      padding: 10px;
      content: "";
      background: $whiteColor;
      position: absolute;
      top: 7px;
      right: 7px;
      bottom: 7px;
      left: 7px;
      border-radius: 20px;
      z-index: 4;
    }

    #bus {
      padding: 60px 10px 20px 10px;
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 5;

      #bus_body {
        height: 100%;
        width: 100%;

        .seat-container {
          // border: 2px solid red;
          height: 100%;
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          // justify-content: space-evenly ;
          flex-direction: column;

          .seat-item {
            position: relative;
            padding: 0;
            margin: 2px 0;

            // &:hover{
            //     // background: red;
            //     cursor: pointer;
            // }

            img {
              height: 100%;
              width: 100%;
            }

            .seat-id {
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
              color: $whiteColor;
              font-size: 70%;
              pointer-events: none;
            }
          }
        }
      }
    }

    #wheel-box {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;

      #wheel {
        position: absolute;
        height: 75px;
        width: 5px;
        border-radius: 20px 0 0 20px;
        background: #435a70;
        top: 12%;
        left: -5px;
      }

      #wheel-2 {
        position: absolute;
        height: 75px;
        width: 5px;
        border-radius: 0 20px 20px 0;
        background: #435a70;
        top: 12%;
        right: -5px;
      }

      #wheel-3 {
        position: absolute;
        height: 75px;
        width: 5px;
        border-radius: 0 20px 20px 0;
        background: #435a70;
        top: 70%;
        right: -5px;
      }

      #wheel-4 {
        position: absolute;
        height: 75px;
        width: 5px;
        border-radius: 20px 0 0 20px;
        background: #435a70;
        top: 70%;
        left: -5px;
      }
    }

    #deck {
      position: absolute;
      z-index: 5;
      top: 5px;
      right: 5px;
      left: 5px;
      background: #464444;
      height: 30px;
      border-radius: 10px 10px 5px 5px;

      #deck-2 {}

      #driverdeck {
        position: absolute;
        z-index: 9;
        top: 21px;
        right: 3px;
        height: 13px;
        background: #595858;
        border-radius: 10px;
        width: 77px;
      }

      #steer-box {
        position: absolute;
        z-index: 9;
        top: 5px;
        right: 10px;
        transform: scale(0.8);

        /* Container */
        #steer {
          position: relative;
          width: 40px;
          height: 40px;
        }

        /* Round Part of the wheel */
        #steer_b {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 5px solid #989898;
          transform: rotate(-135deg);
        }

        /* Steering wheel interior */
        #steer_c {
          height: 106%;
          left: -4%;
          position: absolute;
          top: -4%;
          width: 105%;
          border-radius: 50%;
          overflow: hidden;
          z-index: 2;

          transform: rotate(135deg);

          /* Top Left Part */
          &::before {
            content: "";
            height: 12%;
            left: 0px;
            position: absolute;
            top: 30%;
            width: 47.917%;
            border-radius: 0%;
            background: #989898;
            transform: rotate(20deg);
            transform-origin: 20% 120%;
          }

          /* Top Right Part */
          &::after {
            content: "";
            height: 12%;
            right: 0px;
            position: absolute;
            top: 30%;
            width: 47.917%;
            border-radius: 0%;
            background: #989898;
            transform: rotate(-20deg);
            transform-origin: 80% 120%;
          }

          /* Bottom Center Part */
          #steer_d {
            height: 69.17%;
            bottom: -10px;
            position: absolute;
            left: 44%;
            width: 12%;
            border-radius: 0%;
            background: #989898;
          }

          /* Center Center Part */
          #beep {
            border-radius: 100px 100px 180px 180px / 20px 20px 250px 250px;
            height: 29.17%;
            left: 30%;
            position: absolute;
            top: 38%;
            width: 37.5%;
            z-index: 1;
            background: #989898;
          }
        }
      }

      #horn {
        position: absolute;
        z-index: 9;
        top: 21px;
        right: 55px;
        width: 12px;
        height: 12px;
        background: #af0b0b;
        border: 2px solid $whiteColor;
        border-radius: 10px;
      }
    }

    #mirrors {
      position: absolute;
      top: -15px;
      width: 100%;
      height: 30px;
      border-bottom: 3px solid #6e8ca8;
      border-left: 3px solid #6e8ca8;
      border-right: 3px solid #6e8ca8;
      border-radius: 10px;
      z-index: 01;

      #mirror {
        position: absolute;
        top: -0px;
        right: -10px;
        height: 9px;
        width: 22px;
        border-radius: 5px 5px 2px 2px;
        transform: rotate(-5deg);
        background: #435a70;
      }

      #mirror-2 {
        position: absolute;
        top: -0px;
        left: -10px;
        height: 9px;
        width: 22px;
        transform: rotate(5deg);

        border-radius: 5px 5px 2px 2px;
        background: #435a70;
      }
    }
  }
}

.busLabels {
  display: flex;
  justify-content: center;

  &>div {
    padding: 20px 2px 10px 10px;
  }

  img {
    display: block;
    margin: auto;
    width: 25px;
    max-width: 25px;
  }

  strong {
    text-align: center;
    font-size: 13px;
  }
}

// about us page


.about-banner-img {
  &::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(90deg,
        rgba(255, 255, 255, 0.063) 30%,
        rgba(255, 255, 255, 0.45) 90%);
    z-index: 2;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.717) 80%);
    z-index: 2;
  }
}

.image-box {
  padding: 0 100px;
  // height: 400px;
  overflow: hidden;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

.about-btn-container {
  text-align: center;

  .about-btn {
    margin: 10px;
    color: $whiteColor;
    background: $mainColor1;
    border: none;
    padding: 6px 10px;
  }

  .about-btn:hover {
    background: $txt1;
  }
}

.about-banner-img {
  background: linear-gradient(90deg, $mainColor1 27%, $mainColor2 100%);

  width: 100%;
  height: 80vh;
  overflow: hidden;
  margin: 0px;
  padding: 0 100px;
  position: relative;
  display: flex;
  gap: 40px;
  justify-content: space-around;
  align-items: center;
  // .banner-text {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  img {
    width: 50%;
    // height: 60%;
    opacity: 90%;
    object-fit: contain;
  }
}

.banner-text h1 {
  color: $whiteColor;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

.banner-text p {
  color: $whiteColor;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.about-us-section2,
.about-company {
  background: $whiteColor;
  margin: 50px 100px;
}

.box h1 {
  font-weight: 600;
  text-transform: uppercase;
}

.box img {
  width: 100%;
}

.box-hr {
  border: 2px solid $rowHeader;
  width: 20%;
}

.about-company h1 {
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.what-we-offer {
  background: rgba(241, 241, 241, 0.911);
  padding: 40px 100px;
  margin-top: 50px;
}

.what-we-offer-head {
  text-align: center;

  p {
    margin: 30px;
    font-size: 28px;
  }
}

.what-we-offer-box {
  padding: 10px;

  h1 {
    text-align: left;
    font-size: 22px;
    font-weight: 300;
  }
}

//login new ui

.login-ui {
  background-image: url("../images/busBG.jpg");
  background-size: cover;
  overflow: hidden;

  img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 20px;
    left: 30px;
  }

  .row1 {
    // background: $mainColor1;
    height: 100vh;
    backdrop-filter: blur(2px);
  }

  .new_login_box {
    display: flex;
    align-items: center;
    justify-content: center;

    #formContent {
      background-color: rgb(255, 255, 255);
      backdrop-filter: blur(5px);
      // -webkit-backdrop-filter: blur(5px);

      // filter: blur(8px);
      // -webkit-filter: blur(8px);

      -webkit-border-radius: 10px 10px 10px 10px;
      border-radius: 10px 10px 10px 10px;
      //
      background: rgb(223, 241, 255);
      background: linear-gradient(45deg,
          rgba(223, 241, 255, 1) 0%,
          rgba(241, 240, 255, 1) 100%);
      // padding: 30px;
      min-height: 400px;
      min-width: 450px;
      position: absolute;
      padding: 0px;
      -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
      text-align: center;
      display: inline-block;
      margin-bottom: 20px;

      .toggle-row {
        margin-top: 30px;
        // justify-content: center;
        margin-left: 36px;
        margin-right: 36px;
        display: flex;
        align-items: center;

        h2 {
          // float: left;
          // margin-bottom: 20px;
          text-align: left;
          font-weight: 600;
          font-size: 25px;
          margin-bottom: 0;
        }

        .toggle-container {
          margin-left: auto;

          input[type="checkbox"] {
            visibility: hidden;

            &:checked+label {
              transform: rotate(360deg);
              background-color: $mainColor1;

              &:before {
                transform: translateX(20px);
                background-color: $whiteColor;
                left: 0;
              }
            }
          }

          label {
            width: 40px;
            height: 21px;
            border: 2px solid $mainColor1;
            border-radius: 50em;
            position: relative;
            transition: transform 0.75s ease-in-out;
            // transform-origin: 50% 50%;
            cursor: pointer;

            &:before {
              transition: transform 0.75s ease;
              transition-delay: 0.5s;
              content: "";
              display: block;
              position: absolute;
              width: 16px;
              height: 15px;
              background-color: $mainColor1;
              border-radius: 50%;
              top: 1px;
              left: 1px;
            }
          }
        }
      }

      /* FORM TYPOGRAPHY*/

      input[type="button"],
      input[type="submit"],
      input[type="reset"] {
        background-color: #56baed;
        border: none;
        color: $whiteColor;
        padding: 15px 80px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        text-transform: uppercase;
        font-size: 13px;
        -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
        box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
        margin: 5px 20px 40px 20px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      input[type="button"]:hover,
      input[type="submit"]:hover,
      input[type="reset"]:hover {
        background-color: #39ace7;
      }

      input[type="button"]:active,
      input[type="submit"]:active,
      input[type="reset"]:active {
        -moz-transform: scale(0.95);
        -webkit-transform: scale(0.95);
        -o-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
      }

      label {
        font-family: Roboto;
      }

      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="file"] {
        background: none;
        border: none;
        color: #0d0d0d;
        // padding: 15px 32px;
        text-align: left;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 5px;
        width: 85%;
        height: 5vh;
        // border: ;
        border-bottom: 2px solid $whiteColor;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        // -webkit-border-radius: 5px 5px 5px 5px;
        // border-radius: 20px 20px 20px 20px;
      }

      input[type="text"]:focus,
      input[type="password"]:focus,
      input[type="email"]:focus {
        background: none;
        border-bottom: 2px solid #5fbae9;
      }

      input[type="text"]:placeholder,
      input[type="password"]:placeholder,
      input[type="email"]:placeholder {
        color: $borderColor;
      }

      .login-btn {
        margin-top: 20px;
        border: none;
        color: $whiteColor;
        background: $mainColor1;
        border-radius: 25px;
        width: 95%;
        // height: 6vh;
        padding: 5px;
        margin-bottom: 15px;
      }
    }

    .center-h1 {
      text-align: left;
      margin-left: 55px;
      margin-bottom: 0ch;
      margin-top: 20px;
      font-size: 25.92px;
      font-weight: 600;
      text-transform: uppercase;

      .sp-blue {
        color: $mainColor1;
      }
    }
  }
}


.btn-cancel-ticket {
  background-color: red !important;
  color: white !important;
}

.btn-view-receipt {
  background-color: $mainColor1 !important;
  color: white !important;

}


// .signup-ui {
//   .col-md-5 {
//     // margin-left: 380px;
//     // margin-bottom: 25vh;
//     display: flex;
//     // align-items: center;
//     #formContent {
//       background-color: rgb(255, 255, 255);
//       backdrop-filter: blur(5px);
//       // -webkit-backdrop-filter: blur(5px);

//       // filter: blur(8px);
//       // -webkit-filter: blur(8px);

//       -webkit-border-radius: 10px 10px 10px 10px;
//       border-radius: 10px 10px 10px 10px;
//       //
//       background: rgb(223, 241, 255);
//       background: linear-gradient(
//         45deg,
//         rgba(223, 241, 255, 1) 0%,
//         rgba(241, 240, 255, 1) 100%
//       );
//       // padding: 30px;
//       width: 100%;
//       min-height: 300px;
//       min-width: 600px;
//       position: relative;
//       padding: 0px;
//       -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
//       box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
//       text-align: center;
//       .toggle-row {
//         // display: inline-block;
//         justify-content: center;
//         h2 {
//           float: left;
//           margin-bottom: 20px;
//           margin-top: 25px;
//           margin-left: 55px;
//           text-align: left;
//           font-weight: 600;
//           font-size: 25px;
//         }

//         .toggle-container {
//           margin-left: 80%;
//           input[type="checkbox"] {
//             margin-top: 15px;
//             visibility: hidden;
//             &:checked + label {
//               transform: rotate(360deg);
//               background-color: $mainColor1;
//               &:before {
//                 transform: translateX(20px);
//                 background-color: $whiteColor;
//                 left: 0;
//               }
//             }
//           }
//           label {
//             display: flex;
//             width: 40px;
//             height: 20px;
//             border: 2px solid $mainColor1;
//             border-radius: 50em;
//             position: relative;
//             transition: transform 0.75s ease-in-out;
//             // transform-origin: 50% 50%;
//             cursor: pointer;

//             &:before {
//               transition: transform 0.75s ease;
//               transition-delay: 0.5s;
//               content: "";
//               display: block;
//               position: absolute;
//               width: 17px;
//               height: 17px;
//               background-color: $mainColor1;
//               border-radius: 50%;
//               // top: 12px;
//               // left: 12px;
//             }
//           }
//         }
//       }

//       /* FORM TYPOGRAPHY*/
//       .input-row {
//         display: inline-block;
//         input[type="button"],
//         input[type="submit"],
//         input[type="reset"] {
//           background-color: #56baed;
//           border: none;
//           color: $whiteColor;
//           padding: 15px 80px;
//           text-align: center;
//           text-decoration: none;
//           display: inline-block;
//           text-transform: uppercase;
//           font-size: 13px;
//           -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
//           box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
//           -webkit-border-radius: 5px 5px 5px 5px;
//           border-radius: 5px 5px 5px 5px;
//           margin: 5px 20px 40px 20px;
//           -webkit-transition: all 0.3s ease-in-out;
//           -moz-transition: all 0.3s ease-in-out;
//           -ms-transition: all 0.3s ease-in-out;
//           -o-transition: all 0.3s ease-in-out;
//           transition: all 0.3s ease-in-out;
//         }

//         input[type="button"]:hover,
//         input[type="submit"]:hover,
//         input[type="reset"]:hover {
//           background-color: #39ace7;
//         }

//         input[type="button"]:active,
//         input[type="submit"]:active,
//         input[type="reset"]:active {
//           -moz-transform: scale(0.95);
//           -webkit-transform: scale(0.95);
//           -o-transform: scale(0.95);
//           -ms-transform: scale(0.95);
//           transform: scale(0.95);
//         }
//         label {
//           font-family: Roboto;
//         }

//         input[type="text"],
//         input[type="password"],
//         input[type="email"],
//         input[type="file"] {
//           background: none;
//           border: none;
//           color: #0d0d0d;
//           padding: 15px 32px;
//           text-align: left;
//           text-decoration: none;
//           display: inline-block;
//           font-size: 16px;
//           margin: 5px;
//           width: 48%;
//           height: 8vh;
//           // border: ;
//           border-bottom: 2px solid $whiteColor;
//           -webkit-transition: all 0.5s ease-in-out;
//           -moz-transition: all 0.5s ease-in-out;
//           -ms-transition: all 0.5s ease-in-out;
//           -o-transition: all 0.5s ease-in-out;
//           transition: all 0.5s ease-in-out;
//           // -webkit-border-radius: 5px 5px 5px 5px;
//           // border-radius: 20px 20px 20px 20px;
//         }

//         input[type="text"]:focus,
//         input[type="password"]:focus,
//         input[type="email"]:focus {
//           background: none;
//           border-bottom: 2px solid #5fbae9;
//         }

//         input[type="text"]:placeholder,
//         input[type="password"]:placeholder,
//         input[type="email"]:placeholder {
//           color: $borderColor;
//         }
//       }
//       .login-btn {
//         border: none;
//         color: $whiteColor;
//         background: $mainColor1;
//         border-radius: 15px;
//         width: 95%;
//         height: 8vh;
//         margin-bottom: 10px;
//       }
//     }

//     img {
//       height: 20%;
//       padding: 10px;
//     }
//     .center-h1 {
//       text-align: left;
//       margin-left: 55px;
//       margin-bottom: 0ch;
//       margin-top: 20px;
//       font-size: 25.92px;
//       font-weight: 600;
//       text-transform: uppercase;

//       .sp-blue {
//         color: $mainColor1;
//       }
//     }
//   }
// }

.signup-ui {
  .row {
    padding: 0 40px;
  }
}

//My Tickets
.my-tickets-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, $mainColor1 27%, $mainColor2 100%);
  height: 20vh;
  // margin-bottom: 20px;
}

// Payment receipt

#payment-receipt {
  // background-image: url(../images/logo.jpg);
  // background-repeat: no-repeat;
  position: relative;

  &:after {
    content: "";
    background-image: url(../images/logo.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    z-index: 1;
    opacity: 0.1;
  }

  .receipt-table tr {
    vertical-align: top;
  }

  .receipt-head-title {
    z-index: -10;
  }

  // .receipt-watermark {
  // }
  .receipt-logo-head {
    .receipt-logo {

      // float: left;
      img {
        height: 100px;
      }
    }

    .receipt-title {
      // float: right;
    }
  }
}

.receipt-pdf-view {
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

//permission
.permission-box {
  max-height: 69vh;
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 10px;
}

//CMS
.cmsSliderImageDiv {
  border: 1px solid $borderColor;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .cmsSliderImageHolder {
    padding: 5px;
    margin: 15px;
    border: 1px solid $borderColor;
    cursor: pointer;
    position: relative;

    .sliderRemoveBtn {
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .showRemoveBtn {
      display: block !important;
      z-index: 100;
    }

    img {
      width: 100%;
      z-index: 80;
      object-fit: contain;
    }

    .imageoverlay {
      display: none;
    }

    .boxOverlay {
      background-color: $overlayColor;
      z-index: 90;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .insideImageHolder {
      width: 250px;
      height: 250px;
      justify-content: center;
      // align-items: center;
      display: flex;
    }
  }

  .cmsSliderImageHolderThumb {
    width: 150px !important;
    height: 150px !important;
  }

  .blurFilter {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
  }
}

.cmsCk {
  .ck-content {
    height: 50vh !important;
  }
}

.tandCBox {
  border: 1px solid $borderColor;
  padding: 25px;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.cms-blog {
  .MuiFormControlLabel-label {
    font-weight: bold !important;
  }

  .ck-content {
    height: 150px;
  }
}

.blogThumbnail {
  width: 80px;
  height: 80px;
  border: 1px solid $borderColor;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.paymentHeader {
  background: linear-gradient(90deg,
      rgba($mainColor1, 1) 27%,
      rgba($mainColor2, 1) 100%);
  color: $whiteColor;
  padding: 8px;
  text-shadow: 2px 2px 3px $shadowColor;
  margin-bottom: 10px;
}





// view details component

.view-detail-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-items: center;
  // height: 100vh;

  .view-details-box {
    padding: 20px;

    .dashboard-box {
      width: 100%;
    }

  }
}



//dashbord ui

.dashboard-reports,
.view-detail-container {

  .dashboard-routes {
    p {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .dashboard-box {
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    border-radius: 25px;
    margin: 10px;

    .dashboard-box-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        // background-color: #39ace7;
        // color: white;
        // padding-left: 40px;
        // padding: 10px;
        // margin-left: -40px;
      }
    }
  }

  p {
    font-weight: 600;
    font-size: 28px;
    // text-align: right;
  }
}



.clockSize {
  zoom: 75%;
  // padding: 50px;
  margin: 100px 0px;
}


.chartDataToggler {
  display: flex;
  justify-content: space-evenly;

  .chartToggler {
    margin-top: 20px;

    background-color: white;
    padding: 10px;
    border: none;
    color: black;
    font-weight: 400;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    width: 120px;
  }

  .activeChartToggler {
    background-color: $mainColor1;
    color: white;
    font-weight: 600;
  }

}



.tabs-div {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 10px;

  .tabs-div-box {
    width: 100%;
    padding: 10px;
    font-weight: 400;
    font-size: 22px;
    cursor: pointer;


  }

  .activeTicketTab {
    background-color: $mainColor1;
    color: white;
    font-weight: 600;

  }
}


.jodit-react-container {
  height: 80vh;
}

.jodit-container {
  height: 76vh !important;
}

.jodit-workplace {
  height: 80vh;
}

.jodit-wysiwyg {
  height: 80vh;
}



.contact-dashboard {
  label {
    font-size: 22px;
  }

  .socialIconsData label {
    font-size: 18px;
  }
}



//maps
.map-container-dashboard iframe {
  width: 100%;
  height: 500px;
}

.map-container iframe {
  width: 100%;
  height: 500px;

}


.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: $mainColor1;
}

input:focus+.slider {
  box-shadow: 0 0 1px black;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




//responsive table


.table-responsive {
  overflow-x: auto;
  padding: 10px;

}

table {

  width: 100%;
}


// ::-webkit-scrollbar {
//   display: none;
// }





@media (max-width: 967.5px) {
  .about-banner-img {
    flex-direction: column-reverse;
    gap: 10px;
    justify-content: start;
    height: auto;
    padding: 20px 50px;
  }


  .banner-text h1 {
    font-size: 30px;

  }

  .banner-text p {
    font-size: 14px;

  }

  .about-contents {
    padding: 20px 50px !important;
  }


  .contact-container {
    padding: 20px 25px !important;
  }

  .faq-container {
    margin: 20px 50px !important;
  }

  #formContent {
    // min-width: 350px !important;
    width: 90%;
  }

  .blog-container {
    margin: 20px 40px !important;
  }


  .signup-extra {
    padding: 0 40px;
  }
}


.bus-types-radio {
  width: 100%;
  cursor: pointer;
}


table tbody {
  font-size: 12px;
}