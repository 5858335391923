@import "../../../assets/scss/variables";

.faq-container {
  //   .faq-head {
  margin: 60px 120px;
  // border-bottom: 1px solid $mainColor1;
  //   }
  .faq-img {
    padding: 20px;
    img {
      width: 100%;
    }
  }

  .faq-contents {
    .faqs {
      margin-bottom: 20px;
      border: 1px solid $borderColor;
      padding: 10px;

      .faq-questions {
        cursor: pointer;
        h4 {
          font-weight: 500;
          color: $mainColor1;
        }
      }
      .faq-answers {
        padding: 0px 25px;
        // font-size: 16px;
      }
    }
  }
}
