@import "./variables";

.filter-wrapper {
  padding: 20px;
}
.filter-container {
  background-color: $whiteColor;
  // display: flex;
  align-items: center;
  //   flex-wrap: wrap;
  padding: 0 20px;

  justify-content: space-around;
}

.input-boxes {
  margin: 10px;
}

.search-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
