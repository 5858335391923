@import "./variables";

table {
  vertical-align: middle !important;
}

//Login Page
.loginBg {
  background: url("../images/loginBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  opacity: 0.3;
}
.loginBox {
  // border: 1px solid $borderColor;
  padding: 15px;
  width: 500px;
  position: relative;
  background: $whiteColor;
  box-shadow: 2px 3px 3px $shadowColor;
  .loginHeader {
    background: linear-gradient(
      90deg,
      rgba($mainColor1, 1) 27%,
      rgba($mainColor2, 1) 100%
    );
    color: $whiteColor;
    padding: 5px;
    font-size: 25px;
    text-shadow: 2px 2px 3px $shadowColor;
  }
}

//Sidebar
.sidebarHeader {
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.pro-sidebar {
  .pro-sidebar-inner {
    height: 100vh !important;
    img.sidebar-bg {
      opacity: 0.1 !important;
    }
  }
}

//Travel Agency
.imageHolder {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.logoDisplay {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

//Travel Agency Routes
.routeMainHolder {
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
  .createRouteHolder {
    border: 1px solid $borderColor;
    padding: 8px;
    margin-bottom: 15px;
  }
}

//Buses
.busImageHolder {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $borderColor;
  margin-top: 10px;
  justify-content: flex-start;
  div {
    margin: 5px;
    position: relative;
    border: 1px solid $borderColor;
    padding: 21px;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}

.busFullImageHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  div {
    margin: 10px;
    position: relative;
    border: 1px solid $borderColor;
    padding: 5px;
    cursor: pointer;
  }
}

//Bus Type Seat Configuration

.busConfigurationUIHolder {
  height: 73.2vh;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (min-width: 576px) {
  .busConfigure {
    margin: 0.75rem auto !important;
  }
}

.rowHeader {
  background-color: $txt1;
  padding: 5px 0;
  .rowTexts {
    margin-top: 3px;
    color: $whiteColor;
    text-shadow: 1px 2px 1px $shadowColor;
  }
}

.seatColumns {
  .card {
    border-radius: 0;
    .card-body {
      padding: 5px;
      input[type="text"] {
        border: 1px solid $borderColor;
        border-radius: 5px;
      }
    }
  }
}

//Bus Routes
.routePointHeader {
  background-color: $txt1;
  color: $whiteColor;
  padding: 5px 8px;
  letter-spacing: 0.5px;
}

.routePointHolder {
  border: 1px solid $borderColor;
  padding: 5px;
  max-height: 55.9vh;
  overflow-x: hidden;
  overflow-y: auto;
}

//Bus Scheduler
.routeScheduleHolder {
  border: 1px solid $borderColor;
  padding: 5px;
  max-height: 60.9vh;
  overflow-x: hidden;
  overflow-y: auto;
  .routeScheduleHeader {
    background-color: $txt1;
    color: $whiteColor;
    padding: 5px 8px;
  }
}

// .scheduler {
//   .css-1s2u09g-control {
//     .css-g1d714-ValueContainer {
//       .css-1rhbuit-multiValue {
//         .css-xb97g8 {
//           display: none !important;
//         }
//       }
//     }
//   }
// }

// roles and permissions

.permissions-title {
  background: $mainColor1;
  display: flex;
  // justify-content: center;
  color: $whiteColor;
  padding: 5px;
  margin-bottom: 5px;
}

.permission-box {
  margin-bottom: 15px;

  .col {
    margin-bottom: 10px;
  }
}

.permisiion-edit-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
