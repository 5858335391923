@import "../../../assets//scss/variables";

.t-and-c-container {
  background: url("../../../assets/images/bus2.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Raleway", sans-serif;
  position: relative;

  &:after {
    -webkit-backdrop-filter: blur(
      8px
    ); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdrop-filter: blur(8px); /* Supported in Chrome 76 */

    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .t-and-c-content {
    padding: 5% 10%;
    max-width: 1500px;
    display: block;
    margin: auto;
    color: aliceblue;
    z-index: 2;
    position: relative;
    h1 {
      background: linear-gradient(90deg, #6c63ff80 27%, #ff919080 100%);
      padding: 20px;
      margin-bottom: 0;
      border-bottom: 3px solid #fff;
    }

    span {
      // background: rgba($color: #000000, $alpha: 0.7);
      background: linear-gradient(90deg, #6c63ff80 27%, #ff919080 100%);
      display: block;
      padding: 10px 25px;
      margin-bottom: 40px;
    }
  }
}
