@import "../../../assets/scss/variables";

.contact-container {
  //   background: $backgroundColor;
  overflow: hidden;
  padding: 20px 100px;
  margin-bottom: 40px;
  .contact-box {
    background: white;
    border-radius: 25px;
    box-shadow: 2px 4px 7px 2px rgb(194, 194, 194);
    // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  .contact-info {
    padding: 40px 60px;
    background: linear-gradient(
      120deg,
      rgba($mainColor1, 1) 20%,
      rgba($mainColor2, 1) 100%
    );
    color:white;
    border-radius: 25px;
    .contact-icon-text {
      display: flex;
      //   justify-content: space-around;

      span {
        margin-top: 20px;
        margin-right: 15px;
      }
    }

    .contact-social-icons {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 10px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        // margin-right: 10px;
      }
      span:hover {
        background-color: $mainColor1;
      }
    }
  }

  .contact-inputs {
    padding: 30px 50px;

    textarea {
      border: 0;
      border-bottom: 2px solid $mainColor2;
    }
    .contact-inputs-box {
      display: flex;
      input {
        border: 0;
        border-bottom: 2px solid $mainColor2;
      }

      .flex-column {
        margin-bottom: 15px;
        width: 100%;
        margin-right: 10px;
      }
    }

    .contact-btn {
      margin-top: 20px;
      button {
        padding: 6px 10px;
        color: white;
        background-color: $mainColor2;
        border: none;
      }
    }
  }
}

.maps-container {
  width: 100%;
  iframe {
    width: 100%;
    height: 400px;
  }
}
