$whiteColor: #fff;
$mainColor1: #6c63ff;
$mainColor2: #ff9190;
$txt1: #445a70;
$ff1: "Source Sans Pro", sans-serif;
$borderColor: #ccc;
$shadowColor: rgba(0, 0, 0, 0.33);
$rowHeader: #0b5ed7;
$lightGrey: rgb(209, 209, 209);
// $overlayColor: rgba(255, 255, 255, 0.8);
$overlayColor: #6c63ff30;
$backgroundColor: rgb(240, 240, 240);
