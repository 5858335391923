@import "./variables";
// body {
//   font-family: "adobe-clean", sans-serif !important;
// }

.fullWindow-Spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  color: $whiteColor;
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

//backToDashboard
.backToDashboard {
  background: rgba(1, 1, 1, 0.8);
  color: #adadad;
  padding: 4px 10px;
  text-decoration: none;
  &:hover {
    color: $whiteColor;
  }
}

//dashboard
.dashboardHeader {
  background: linear-gradient(
    90deg,
    rgba($mainColor1, 1) 27%,
    rgba($mainColor2, 1) 100%
  );
  color: $whiteColor;
  padding: 10.5px;
  font-size: 22px;
  text-shadow: 2px 2px 3px $shadowColor;
  text-align: center;
}

//Custom ScrollBar
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $mainColor1;
}

// booking modal form

.booking-modal-form input {
  // background-color: rgb(248, 248, 248);
  border: none;
  box-shadow: 0px 3px 10px 2px rgb(240, 240, 240);
  padding: 6px;
}

._2iWL7,
._1bpcM {
  z-index: 100 !important;
}

.paginationContainer {
  display: flex;
  margin: 10px;
  justify-content: center;
}
